<template>
  <div class="d-flex mt-8 justify-center btn-wrapper">
    <v-btn
      class="mr-2 perform-adk-train-save"
      x-large
      :disabled="
        userType === 'participant' && adkObject && adkType === adkObject.name && !isSaveBtnEnabled
      "
      rounded
      outlined
      :loading="saving"
      depressed
      @click="$emit('save-progress')"
    >
      Save
    </v-btn>
    <v-btn
      x-large
      :disabled="disableComplete"
      depressed
      rounded
      :loading="completing"
      color="#000000"
      class="white--text ml-2 perform-adk-train-complete font-weight-bold"
      type="submit"
      @click="$emit('emphasize-completion')"
    >
      {{ userType === 'participant' ? 'COMPLETE ACTIVITY' : 'SETUP COMPLETE' }}
    </v-btn>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  name: 'MSaveAdkOnly',
  props: {
    value: {
      required: false,
      type: Object,
      default: () => {}
    },
    loading: {
      required: false,
      type: Boolean
    },
    isSaveBtnEnabled: {
      required: false,
      type: Boolean
    },
    disableComplete: {
      type: Boolean,
      default: false
    },
    adkType: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      default: false
    },
    completing: {
      type: Boolean,
      default: false
    },
    userType: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const programDoc = computed(() => {
      return props.value;
    });
    const adkObject = programDoc?.value?.data?.adks?.find(adk =>
      adk.fields?.find(field => field.name === 'm-timesheet-default')
    );
    return {
      adkObject
    };
  }
};
</script>

<style scoped lang="scss">
.btn-wrapper {
  flex-wrap: wrap;
  gap: 10px;
}
.perform-adk-train-save,
.perform-adk-train-complete {
  @media (max-width: 575px) {
    margin: 0 !important;
    width: 100%;
  }
}
</style>
