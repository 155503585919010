








































import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: 'MInstruction',
  components: {
    Instruct: () => import('@/components/molecules/m-instruct.vue')
  },
  props: {
    instruction: {
      required: true,
      type: Object,
      default: Object
    }
  },
  setup(props, ctx) {
    const showInstructions = ref(true);
    const instructionValue = computed({
      get: () => props.instruction,
      set: newValue => {
        ctx.emit('input', newValue);
      }
    });
    watch(instructionValue, val => {
      if (val) {
        showInstructions.value = true;
      }
    });
    return {
      showInstructions,
      instructionValue
    };
  }
};
