

import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
import MInstruction from '../../molecules/m-instruction.vue';
import MSaveAdkOnly from '../../molecules/m-save-adk-only.vue';
import { Field } from '../index';
import fileStorage from '../../../store/modules/fileStorage/index';
import {
  useFileStorageState,
  useFileStorageGetters,
  useFileStorageMutations,
  useFileStorageActions
} from '@/store'; // AWS S3

interface EmployerFlyer {
  fileType: string[];
  uploadLimit: {
    min: number;
    max: number;
  };
}
interface ParticipantFlyer {
  fileUpload: {
    timestamp: string;
    fileName: string;
    file: File;
    description: string;
  }[];
}

interface FlyerField extends Field {
  setup: EmployerFlyer;
  value: ParticipantFlyer;
}
export default defineComponent({
  name: 'MFlyers',
  components: {
    MInstruction,
    MSaveAdkOnly
  },
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    test: {
      type: String,
      default: ''
    }
  },
  setup(_props, _ctx) {
    const { uploadItem } = useFileStorageActions(['uploadItem']); // AWS S3
    const { deleteItem } = useFileStorageActions(['deleteItem']); // AWS S3
    /*
    const uint8 = new Uint8Array(4);
    uint8[0] = 1;
    uint8[1] = 2;
    uint8[2] = 3;
    uint8[3] = 4;
    */
    // uploadItem({ item: uint8 }); //
    //uploadItem({ item: 'hello' }); // AWS S3, use inside the file input field to upload files into the test bucket
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });
    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Flyers';
        }
        return false;
      });
      return indx;
    });
    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-flyers';
          }
          return false;
        }
      );
      return Fdindx;
    });
    const fieldValue = computed<FlyerField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });
    const userTypeValue = computed(() => {
      if (
        _props.userType === 'organizer' &&
        _ctx.root.$route.name !== 'Monitor' &&
        !_props.isPreview
      ) {
        return 'organizer';
      }
      if (_props.isPreview) return 'participant';
      if (_props.isVisitor) return 'organizer';
      return 'participant';
    });

    const userIsEmployer = ref(userTypeValue.value === 'organizer');
    // organizerData
    const organizerFlyerPresets = computed<EmployerFlyer>({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });
    // participantData
    const participantFlyerUploads = computed<ParticipantFlyer>({
      get: () => {
        if (!fieldValue.value.value.fileUpload) {
          fieldValue.value.value.fileUpload = [];
        }
        return fieldValue.value.value;
      },
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    // participantFlyerUploads.value.fileUpload = [];

    const fileUploadsTableHeaders = [
      { text: 'Timestamp', value: 'timestamp' },
      { text: 'File Name', value: 'file name' },
      { text: 'Description', value: 'description' },
      { text: 'Preview', value: 'preview' },
      { text: 'Delete', value: 'delete' }
    ];

    type FileUpload = {
      timestamp: string;
      fileName: string;
      file: File;
      description: string;
    };

    // const fileUploads: Ref<FileUpload[]> = ref([]);

    const latestFlyerUpload = ref({
      fileName: '',
      file: null,
      description: ''
    });
    let filesAllowed = ref('image/*');
    const organizerActions = {
      setAllowedFiles() {
        console.log(organizerFlyerPresets.value.fileType);
        filesAllowed =
          organizerFlyerPresets.value.fileType.length == 0
            ? 'image/*'
            : organizerFlyerPresets.value.fileType.join(',');
        console.log(filesAllowed);
      }
    };
    const dialog = ref(false);
    const uploadsLeft = computed(() => {
      const minUploads = organizerFlyerPresets.value.uploadLimit.min;
      const numUploads = participantFlyerUploads.value.fileUpload.length;
      return numUploads < minUploads ? minUploads - numUploads : 0;
    });
    const maxUploadsReached = computed(() => {
      const numUploads = participantFlyerUploads.value.fileUpload.length;
      return organizerFlyerPresets.value.uploadLimit.max - numUploads <= 0;
    });
    const maxUploads = organizerFlyerPresets.value.uploadLimit.max;
    const fileCurrentlyBeingViewedId = ref(0);
    let id = 0;

    const currentImageURL = ref('');
    const currentImageName = ref('');
    const currentImageDesc = ref('');

    const participantActions = {
      addFlyer() {
        console.log('addFlyer() called');
        if (latestFlyerUpload.value.file != null) {
          let ts = new Date().toISOString(); // maybe make this random generated char later
          /*
          let now = new Date(); // make it dd-mm-yyyy
          let day = String(now.getDate()).padStart(2, '0');
          let month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
          let year = now.getFullYear();
          let ts2 = `${day}-${month}-${year}`;
          */
          participantFlyerUploads.value.fileUpload.push({
            timestamp: ts,
            fileName: latestFlyerUpload.value.file.name,
            file: latestFlyerUpload.value.file,
            description: latestFlyerUpload.value.description
          });
          console.log('fileUpload', latestFlyerUpload);
          uploadItem({
            name: ts + '---' + latestFlyerUpload.value.file.name,
            item: latestFlyerUpload.value.file
          });
          latestFlyerUpload.value.fileName = '';
          latestFlyerUpload.value.file = null;
          latestFlyerUpload.value.description = '';
          _ctx.emit('save-progress');
        }
      },
      viewFile(item) {
        // fileUploads.indexOf(fileUploads.filter((i: any) => i.id == item.id)[0])
        console.log('viewFile() called');
        console.log(item);
        currentImageName.value = item.fileName;
        currentImageURL.value =
          'https://posted-flyers-bucket2.s3.us-west-1.amazonaws.com/' +
          item.timestamp +
          '---' +
          encodeURIComponent(item.fileName);
        currentImageDesc.value = item.description;
        dialog.value = true;
      },
      deleteFile(item) {
        console.log('eeee');
        // console.log(_props.test);
        deleteItem({ key: item.timestamp + '---' + item.fileName });
        const index = participantFlyerUploads.value.fileUpload.findIndex(file => file === item);
        participantFlyerUploads.value.fileUpload.splice(index, 1);
        _ctx.emit('save-progress');
      }
    };
    if (!userIsEmployer) {
      organizerFlyerPresets.setAllowedFiles();
    }

    return {
      fieldValue,
      participantFlyerUploads,
      organizerFlyerPresets,
      organizerActions,
      participantActions,
      userIsEmployer,
      latestFlyerUpload,
      uploadsLeft,
      maxUploadsReached,
      maxUploads,
      fileUploadsTableHeaders,
      fileCurrentlyBeingViewedId,
      dialog,
      uploadItem,
      deleteItem,
      filesAllowed,
      currentImageURL,
      currentImageName,
      currentImageDesc
      // onFilesAdded
    };
  }
});
