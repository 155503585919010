var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-8"},[(_vm.userIsEmployer)?_c('div',[_c('v-container',{staticClass:"activity-boilerplate"},[_c('v-row',{staticClass:"activity-boilerplate__form",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"flyer_header mb-4"},[_vm._v("Select preferred file types:")]),_c('v-select',{attrs:{"clearable":"","chips":"","label":"Select","hint":"All images are permitted by default","persistent-hint":"","items":['.png', '.jpg', '.pdf', '.jpeg', '.tiff', '.raw', '.psd'],"multiple":"","variant":"outlined"},on:{"change":function($event){return _vm.organizerActions.setAllowedFiles()}},model:{value:(_vm.organizerFlyerPresets.fileType),callback:function ($$v) {_vm.$set(_vm.organizerFlyerPresets, "fileType", $$v)},expression:"organizerFlyerPresets.fileType"}}),_c('h4',{staticClass:"flyer_header mb-4 mt-6"},[_vm._v("Upload Limit:")]),_c('v-text-field',{attrs:{"label":'Maximum',"outlined":"","rounded":"","placeholder":"3","type":"number"},model:{value:(_vm.organizerFlyerPresets.uploadLimit.max),callback:function ($$v) {_vm.$set(_vm.organizerFlyerPresets.uploadLimit, "max", $$v)},expression:"organizerFlyerPresets.uploadLimit.max"}}),_c('v-text-field',{attrs:{"label":'Minimum',"outlined":"","rounded":"","placeholder":"2","type":"number"},model:{value:(_vm.organizerFlyerPresets.uploadLimit.min),callback:function ($$v) {_vm.$set(_vm.organizerFlyerPresets.uploadLimit, "min", $$v)},expression:"organizerFlyerPresets.uploadLimit.min"}})],1)],1)],1)],1):_c('div',[_c('MInstruction',{attrs:{"instruction":{
        youtubeUrl: '',
        discordUrl: '',
        boilerInstructions: [
          'Digitally design your flyer and save it as an image file',
          'Upload and describe your flyer',
          'Repeat until the required amount of flyers has been reached'
        ],
        goal: 'To design and submit digital flyers to promote content in social media'
      }}}),_c('v-file-input',{attrs:{"show-size":"","label":"Upload your flyer","accept":_vm.filesAllowed,"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","outlined":"","rounded":"","placeholder":"Upload a file","type":"file"},model:{value:(_vm.latestFlyerUpload.file),callback:function ($$v) {_vm.$set(_vm.latestFlyerUpload, "file", $$v)},expression:"latestFlyerUpload.file"}}),_c('v-textarea',{staticClass:"module-default__textarea",attrs:{"rounded":"","auto-grow":"","placeholder":"Describe your flyer","prepend-inner-icon":"mdi-comment","rows":"3","outlined":"","h-auto":"","label":"Description"},model:{value:(_vm.latestFlyerUpload.description),callback:function ($$v) {_vm.$set(_vm.latestFlyerUpload, "description", $$v)},expression:"latestFlyerUpload.description"}}),_c('v-btn',{staticClass:"white--text font-weight-bold",attrs:{"color":"green","large":"","depressed":"","rounded":"","block":"","disabled":_vm.maxUploadsReached},on:{"click":function($event){return _vm.participantActions.addFlyer()}}},[_vm._v(" Add Flyer ")]),_c('div',{staticClass:"d-flex mt-4 justify-center",attrs:{"id":"uploadsLeft"}},[_c('v-btn',{staticClass:"font-weight-regular",attrs:{"small":"","outlined":"","rounded":"","ripple":false,"color":"green"}},[_vm._v(" "+_vm._s(_vm.uploadsLeft)+" uploads left to unlock ")])],1),(_vm.maxUploadsReached)?_c('div',{staticClass:"d-flex mt-4 justify-center"},[_c('v-btn',{staticClass:"font-weight-regular",attrs:{"small":"","outlined":"","rounded":"","ripple":false,"color":"error"}},[_vm._v(" Can only upload up to "+_vm._s(_vm.maxUploads)+" flyers ")])],1):_vm._e(),_c('v-data-table',{staticClass:"mt-12",attrs:{"hide-default-footer":"","headers":_vm.fileUploadsTableHeaders,"items":_vm.participantFlyerUploads.fileUpload},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.timestamp))]),_c('td',[_vm._v(_vm._s(item.fileName))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_c('v-btn',{attrs:{"outlined":"","rounded":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.participantActions.viewFile(item)}}},[_c('v-icon',{attrs:{"size":"medium","color":"primary"}},[_vm._v(" mdi-magnify ")]),_vm._v("View ")],1)],1),_c('td',[_c('v-icon',{attrs:{"size":"x-large","color":"error"},on:{"click":function($event){return _vm.participantActions.deleteFile(item)}}},[_vm._v(" mdi-delete ")])],1)])]}}])}),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"overflow-hidden"},[_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","outlined":"","rounded":""},on:{"click":function () {
                _vm.dialog = false;
              }}},[_c('v-icon',{attrs:{"size":"x-large"}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.currentImageName)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.currentImageDesc)+" ")])],1),_c('v-col',[_c('img',{staticClass:"popup_image ma-4",attrs:{"src":_vm.currentImageURL,"alt":"Preview"}})])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }